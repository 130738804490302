import React, { useRef, useState, useEffect } from 'react';
import { Alert, IconButton, Slide, Snackbar as MuiSnackbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from '../store';
import { closeSnackbar } from '../store/slices/snackbar';
import useConfig from '../hooks/useConfig';
import { Icon } from '@iconify/react/dist/iconify.js';

const animations = {
    SlideLeft: (props) => <Slide {...props} direction="left" />,
    SlideUp: (props) => <Slide {...props} direction="up" timeout={{ enter: 400, exit: 300 }} />,
    SlideRight: (props) => <Slide {...props} direction="right" />,
    SlideDown: (props) => <Slide {...props} direction="down" />,
};

const SnackbarStack = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { matchDownMd } = useConfig();

    const { snackbars } = useSelector((state) => state.snackbar);

    const [snackbarHeights, setSnackbarHeights] = useState([]);
    const snackbarRefs = useRef([]);

    useEffect(() => {
        const heights = snackbarRefs.current.map((ref) => ref?.offsetHeight || 0);
        if (JSON.stringify(heights) !== JSON.stringify(snackbarHeights)) {
            setSnackbarHeights(heights);
        }
    }, [snackbars, snackbarHeights]);

    const handleClose = (event, reason, id) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar(id));
    };

    return (
        <>
            {snackbars.map(({ id, message, anchorOrigin, variant, alert, transition, close }, index) => (
                <MuiSnackbar
                    key={id}
                    anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'right' }}
                    open={true}
                    autoHideDuration={3000}
                    onClose={(event, reason) => handleClose(event, reason, id)}
                    TransitionComponent={animations[transition] || animations.SlideUp}
                    ref={(el) => {
                        snackbarRefs.current[index] = el;
                    }}
                    sx={{
                        width: { xs: '90%', sm: '500px' },
                        borderRadius: '5px',
                        left: !matchDownMd ? '50%' : '65%',
                        transform: !matchDownMd ? 'translateX(-5%)' : 'translateX(-65%)',
                        mb: `${snackbarHeights.slice(0, index).reduce((sum, h) => sum + h + 12, 0)}px`, // 16px is the margin between snackbars
                    }}
                >
                    {variant === 'alert' ? (
                        <Alert
                            variant={alert.variant}
                            color={alert.color}
                            severity={alert.color}
                            action={
                                <>
                                    {close !== false && (
                                        <IconButton
                                            size="small"
                                            aria-label="close"
                                            onClick={(event) => handleClose(event, 'closeButton', id)}
                                        >
                                            <CloseIcon
                                                fontSize={'10px'}
                                                className="font-bold"
                                                style={{
                                                    color: theme.palette.text.primary,
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </>
                            }
                            icon={false}
                            sx={{
                                backgroundColor: theme.palette.background.default,
                                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                border: `1px solid ${theme.palette.border.main}`,
                                color: theme.palette.text.primary,
                                ...(alert.variant === 'outlined' && {
                                    bgcolor: 'background.paper',
                                }),
                                padding: '9px 20px',
                                minWidth: !matchDownMd ? '23rem' : 'auto',
                                '& .MuiAlert-message': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '6px'
                                }
                            }}
                        >
                            {alert?.color === 'success' ?
                                <Icon icon="charm:circle-tick" style={{ color: theme.palette.success.main, height: '18px', width: '18px' }} />
                                :
                                <Icon icon="mdi:cross-circle" style={{ color: theme.palette.error.main, height: '18px', width: '18px' }} />
                            }
                            {message}
                        </Alert>
                    ) : (
                        <div>
                            {message}
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={(event) => handleClose(event, 'closeButton', id)}
                                sx={{ mt: 0.25 }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                </MuiSnackbar>
            ))}
        </>
    );
};

export default SnackbarStack;
