import PropTypes from 'prop-types';

import { useSelector } from '../../store';
import NoPermissionPage from '../../views/pages/others/NoPermissionPage';

//Read guard
export const KnowledgeBasesReadGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)
    const isReadAllowed = member_permission?.knowledge_base?.view_knowledge_base

    return isReadAllowed ? children : <NoPermissionPage />
};

KnowledgeBasesReadGuard.propTypes = {
    children: PropTypes.node
};

//Write guard
export const CreateKnowledgeBaseGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isWriteAllowed = member_permission?.knowledge_base?.create_knowledge_base

    return isWriteAllowed ? children : <NoPermissionPage />
};

CreateKnowledgeBaseGuard.propTypes = {
    children: PropTypes.node
};
