
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    knowledgeBases: null,
    singleKnowledgeBase: null,
    loading: false,
    saveLoading: false,
    singleKnowledgeBaseLoading: false,

    totalPages: null,
    totalCount: null,
};

const slice = createSlice({
    name: 'knowledgeBase',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isSaveLoading(state, action) {
            state.saveLoading = action.payload;
        },

        isSingleKnowledgeBaseLoading(state, action) {
            state.singleKnowledgeBaseLoading = action.payload;
        },

        setKnowledgeBaseDataSuccess(state, action) {
            state.knowledgeBases = action.payload?.data;
            state.totalPages = action.payload?.totalPages;
            state.totalCount = action.payload?.totalCount;
        },

        setSingleKnowledgeBaseDataSuccess(state, action) {
            state.singleKnowledgeBase = action.payload;
        },

        createKnowledgeBaseDataSuccess(state, action) {
            state.knowledgeBases?.push(action.payload);
        },

        deleteKnowledgeBaseDataSuccess(state, action) {
            const newKnowledgeBases = state.knowledgeBases.filter(knowledgeBase => knowledgeBase._id !== action.payload.id);
            state.knowledgeBases = newKnowledgeBases;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getAllKnowledgeBases({ search = '', page, limit }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/knowledge-base/user/all?search=${search}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setKnowledgeBaseDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function createKnowledgeBase(data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.post(`/knowledge-base/create`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            dispatch(slice.actions.createKnowledgeBaseDataSuccess(response.data?.data))

            handleSuccess(response, "Knowledge base created successfully", dispatch);

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function getSingleKnowledgeBase(id) {
    return async () => {
        try {
            dispatch(slice.actions.isSingleKnowledgeBaseLoading(true))

            const response = await axios.get(`/knowledge-base/get-one/${id}`);
            dispatch(slice.actions.setSingleKnowledgeBaseDataSuccess(response?.data?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isSingleKnowledgeBaseLoading(false))
        }
    }
}


export function deleteKnowledgeBase(id) {
    return async () => {
        try {
            const response = await axios.delete(`/knowledge-base/delete/${id}`);
            dispatch(slice.actions.deleteKnowledgeBaseDataSuccess(
                { id }
            ))
            handleSuccess(response, "Knowledge base deleted successfully", dispatch);

            return response.data?.message;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }

    }
}
