
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    apiKeys: null,
    apiKeyLogs: null,
    singleApiKey: null,
    loading: false,
    saveLoading: false,

    totalPages: null,
    totalCount: null,
};

const slice = createSlice({
    name: 'apiKey',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isSaveLoading(state, action) {
            state.saveLoading = action.payload;
        },

        setApiKeyDataSuccess(state, action) {
            state.apiKeys = action.payload?.data;
            state.totalPages = action.payload?.totalPages;
            state.totalCount = action.payload?.totalCount;
        },

        setApiKeyLogDataSuccess(state, action) {
            state.apiKeyLogs = action.payload?.data;
            state.totalPages = action.payload?.totalPages;
            state.totalCount = action.payload?.totalCount;
        },

        setSingleApiKeyDataSuccess(state, action) {
            state.singleApiKey = action.payload;
        },

        updateApiKeyDataSuccess(state, action) {
            const index = state.apiKeys.findIndex(apiKey => apiKey._id === action.payload.id);
            const apiKeyData = state.apiKeys[index];
            const newData = { ...apiKeyData, ...action.payload.data };
            state.apiKeys[index] = newData
        },

        createApiKeyDataSuccess(state, action) {
            state.apiKeys.push(action.payload);
        },

        deleteApiKeyDataSuccess(state, action) {
            const newApiKeys = state.apiKeys.filter(apiKey => apiKey._id !== action.payload.id);
            state.apiKeys = newApiKeys;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getAllApiKeys({ search }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/api_key/user/all?search=${search}`);
            dispatch(slice.actions.setApiKeyDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function createApiKey(data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.post(`/api_key/create`, data);
            dispatch(slice.actions.createApiKeyDataSuccess(response.data?.data))

            handleSuccess(response, "Api key created successfully", dispatch);

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function updateApiKey(id, data) {
    return async () => {
        try {
            dispatch(slice.actions.isSaveLoading(true))

            const response = await axios.put(`/api_key/update/${id}`, data);

            dispatch(slice.actions.updateApiKeyDataSuccess(
                { data, id }
            ))
            handleSuccess(response, "Api key updated successfully", dispatch);

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isSaveLoading(false))
        }
    }
}

export function deleteApiKey(id) {
    return async () => {
        try {
            const response = await axios.delete(`/api_key/delete_key/${id}`);
            dispatch(slice.actions.deleteApiKeyDataSuccess(
                { id }
            ))
            handleSuccess(response, "Api key deleted successfully", dispatch);

            return response.data?.message;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }

    }
}

export function getAllApiKeyLogs({ search = '', page, limit, start_date = '', end_date = '' }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/api_key/logs?search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setApiKeyLogDataSuccess(response?.data))

            return response?.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}