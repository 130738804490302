import { createContext, useLayoutEffect, useState } from "react";
import { useSelector } from "../store";

export const PermissionContext = createContext({});

export const PermissionProvider = ({ children }) => {

    const { member_permission } = useSelector((state) => state.account)

    const NumbersSettingGuard = () => {
        const isWriteAllowed = member_permission?.numbers?.edit_setting
        return isWriteAllowed;
    };

    const WriteAgentGuard = () => {
        const isWriteAllowed = member_permission?.assistants?.create_agent
        return isWriteAllowed;
    }

    const BuySubscriptionGuard = () => {
        const isWriteAllowed = member_permission?.subscription?.purchase_subscription
        return isWriteAllowed;
    }

    const CreateApiKeyGuard = () => {
        const isWriteAllowed = member_permission?.api_key?.create_key
        return isWriteAllowed;
    }

    const InviteMemberInOrg = () => {
        const isWriteAllowed = member_permission?.organization?.invite_member
        return isWriteAllowed;
    }

    const EditMemberInOrg = () => {
        const isWriteAllowed = member_permission?.organization?.edit_member
        return isWriteAllowed;
    }

    const EditOrgSetting = () => {
        const isWriteAllowed = member_permission?.organization?.edit_setting
        return isWriteAllowed;
    }

    const ReadObcGuard = () => {
        const isReadAllowed = member_permission?.outbound_call?.view_obc;
        return isReadAllowed;
    }

    const WriteObcGuard = () => {
        const isWriteAllowed = member_permission?.outbound_call?.create_obc;
        return isWriteAllowed;
    }

    const StartObcGuard = () => {
        const isWriteAllowed = member_permission?.outbound_call?.start_call;
        return isWriteAllowed;
    }

    const ReadKnowledgeBaseGuard = () => {
        const isReadAllowed = member_permission?.knowledge_base?.view_knowledge_base;
        return isReadAllowed;
    }

    const WriteKnowledgeBaseGuard = () => {
        const isWriteAllowed = member_permission?.knowledge_base?.create_knowledge_base;
        return isWriteAllowed;
    }

    return (
        <PermissionContext.Provider
            value={{
                NumbersSettingGuard,
                WriteAgentGuard,
                BuySubscriptionGuard,
                CreateApiKeyGuard,
                InviteMemberInOrg,
                EditMemberInOrg,
                EditOrgSetting,
                WriteObcGuard,
                StartObcGuard,
                ReadObcGuard,
                ReadKnowledgeBaseGuard,
                WriteKnowledgeBaseGuard
            }}
        >
            {children}
        </PermissionContext.Provider>
    );
};
