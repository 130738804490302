

import React from 'react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { Icon } from '@iconify/react/dist/iconify.js';


export const CustomToolTip = ({ element, content, width }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const mode = theme.palette.mode;

    const handlePopperOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handlePopperClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <div
                onMouseEnter={handlePopperOpen}
                onMouseLeave={handlePopperClose}
            >
                {element}
            </div>
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="top-end"
                transition
                style={{ zIndex: 9999 }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>

                        <div className="relative">
                            {content &&
                                <Paper
                                    sx={{
                                        width: width || 'auto',
                                        p: 1.5,
                                        backgroundColor: mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
                                        color: theme.palette.text.primary,
                                        border: `1px solid ${theme.palette.border.main}`,
                                    }}
                                >
                                    {content}
                                </Paper>
                            }
                        </div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
};


// import React from 'react';
// import Popper from '@mui/material/Popper';
// import Fade from '@mui/material/Fade';
// import Paper from '@mui/material/Paper';
// import { useTheme } from '@mui/material/styles';
// import { Icon } from '@iconify/react/dist/iconify.js';

// export const CustomToolTip = ({ element, content, width = 'auto', placement = 'top-end' }) => {
//     const [anchorEl, setAnchorEl] = React.useState(null);
//     const [open, setOpen] = React.useState(false);
//     const theme = useTheme();
//     const mode = theme.palette.mode;

//     const handlePopperOpen = (event) => {
//         setAnchorEl(event.currentTarget);
//         setOpen(true);
//     };

//     const handlePopperClose = () => {
//         setOpen(false);
//     };

//     // Function to get triangle styles based on placement
//     const getTriangleStyles = (placementProp) => {
//         const baseStyles = {
//             position: 'absolute',
//             overflow: 'hidden',
//             width: '20px',
//             height: '10px',
//         };

//         const iconBaseStyles = {
//             color: mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
//             filter: `drop-shadow(0 -2px 1px ${theme.palette.border.main})`,
//             fontSize: '20px',
//             position: 'absolute',
//         };

//         switch (placementProp) {
//             case 'bottom':
//             case 'bottom-start':
//             case 'bottom-end':
//                 return {
//                     triangleContainer: {
//                         ...baseStyles,
//                         top: '-10px',
//                         right: '10px',
//                     },
//                     iconStyles: {
//                         ...iconBaseStyles,
//                         top: 0,
//                         right: 0,
//                         transform: 'rotate(180deg)',
//                     }
//                 };
//             case 'top':
//             case 'top-start':
//             case 'top-end':
//                 return {
//                     triangleContainer: {
//                         ...baseStyles,
//                         bottom: '-10px',
//                         right: '10px',
//                     },
//                     iconStyles: {
//                         ...iconBaseStyles,
//                         bottom: 0,
//                         right: 0,
//                         transform: 'rotate(180deg)',
//                     }
//                 };
//             case 'right':
//             case 'right-start':
//             case 'right-end':
//                 return {
//                     triangleContainer: {
//                         ...baseStyles,
//                         left: '-10px',
//                         top: '10px',
//                     },
//                     iconStyles: {
//                         ...iconBaseStyles,
//                         left: 0,
//                         top: 0,
//                         transform: 'rotate(270deg)',
//                     }
//                 };
//             case 'left':
//             case 'left-start':
//             case 'left-end':
//                 return {
//                     triangleContainer: {
//                         ...baseStyles,
//                         right: '-10px',
//                         top: '10px',
//                     },
//                     iconStyles: {
//                         ...iconBaseStyles,
//                         right: 0,
//                         top: 0,
//                         transform: 'rotate(90deg)',
//                     }
//                 };
//             default:
//                 return {
//                     triangleContainer: baseStyles,
//                     iconStyles: iconBaseStyles,
//                 };
//         }
//     };

//     const { triangleContainer, iconStyles } = getTriangleStyles(placement);

//     return (
//         <div>
//             <div
//                 onMouseEnter={handlePopperOpen}
//                 onMouseLeave={handlePopperClose}
//             >
//                 {element}
//             </div>
//             <Popper
//                 open={open}
//                 anchorEl={anchorEl}
//                 placement={placement}
//                 transition
//                 style={{ zIndex: 9999 }}
//             >
//                 {({ TransitionProps }) => (
//                     <Fade {...TransitionProps} timeout={350}>
//                         <div className="relative">
//                             <div
//                                 style={triangleContainer}
//                             >
//                                 <Icon
//                                     icon="mdi:triangle"
//                                     style={iconStyles}
//                                 />
//                             </div>

//                             <Paper
//                                 sx={{
//                                     width: width,
//                                     p: 2,
//                                     backgroundColor: mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
//                                     color: theme.palette.text.primary,
//                                     border: `1px solid ${theme.palette.border.main}`,
//                                     position: 'relative',
//                                 }}
//                             >
//                                 {content}
//                             </Paper>
//                         </div>
//                     </Fade>
//                 )}
//             </Popper>
//         </div>
//     );
// };