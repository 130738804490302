import { Button, CircularProgress, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const CustomLoadingButton = ({ isLoading = false, icon = '', text, handleClick, disabled = false, textStyle = '', sx = {} }) => {
    const theme = useTheme();
    
    return (
        <Button
            type="button"
            variant="outlined"
            className='outlined-button py-4 flex items-center gap-2'
            onClick={handleClick}
            disabled={isLoading || disabled}
            sx={{
                backgroundColor: theme.palette.background.default,
                ...sx,
            }}
        >
            {isLoading &&
                <CircularProgress size={20} className='mr-2 text-gray-300' />
            }
            {!isLoading && icon}
            <span className={textStyle}>{text}</span>
        </Button>
    );
};


export const MuiGradientLoadingButton = ({ isLoading = false, icon = '', text, sx = {}, gridStyle = {}, handleClick, disabled = false, textStyle = {} }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <Grid
            item
            style={{
                background: theme.palette.gradient.secondary,
                zIndex: 20,
                borderRadius: '30px',
                padding: '1px',
                width: 'fit-content',
                ...gridStyle
            }}
        >
            <Button
                variant="outlined"
                size="large"
                onClick={handleClick}
                style={{
                    zIndex: 50,
                    border: 'none',
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    padding: '8px 18px',
                    ...sx
                }}
            >
                {isLoading &&
                    <CircularProgress size={20} className='mr-2 text-gray-300' />
                }
                {!isLoading && icon}
                <span style={{ marginLeft: '10px', ...textStyle, }}>{text}</span>
            </Button>
        </Grid>
    )
}