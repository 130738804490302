import PropTypes, { element } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import useConfig from '../hooks/useConfig';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = React.forwardRef(
    (
        {
            border = false,
            boxShadow,
            children,
            element,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            hideDivider = false,
            offsetTop = 150,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
        const palette = theme.palette;
        const mode = palette.mode
        const { matchDownMd, screenWidth } = useConfig();

        const cardContentRef = useRef(null);
        const [cardHeight, setCardHeight] = useState(`calc(100vh - ${offsetTop}px)`);

        // Calculate card height dynamically
        const calculateCardHeight = () => {
            if (!matchDownMd && cardContentRef.current) {
                const offsetTop = cardContentRef.current.offsetTop;
                const headerHeight = document.querySelector('header')?.offsetHeight || 0; // Account for any sticky header
                setCardHeight(`calc(100vh - ${offsetTop + headerHeight + 10}px)`);
            }
        };
        // Handle window resize and recalculation
        useEffect(() => {
            calculateCardHeight();
            window.addEventListener('resize', calculateCardHeight);
            return () => {
                window.removeEventListener('resize', calculateCardHeight);
            };
        }, []);

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[300] + 98,
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow ||
                            (theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                            : 'inherit'
                    },
                    background: mode === 'dark' ? (palette.background.paper) : (matchDownMd ? 'white' : palette.background.paper),
                    ...sx
                }}
            >
                <Grid
                    container
                    alignItems='center'
                    justifyContent={'space-between'}
                    sx={{
                        flexShrink: 0,
                        backgroundColor: (mode === 'dark' && hideDivider) ? palette.background.paper : (hideDivider) ? palette.background.paper : palette.background.default
                    }}
                >
                    <Grid item className='w-fit sm:w-1/2'>
                        {!darkTitle && title && (
                            <CardHeader
                                sx={{
                                    ...headerSX,
                                    padding: matchDownMd ? '18px' : '24px',
                                    paddingLeft: matchDownMd ? '18px' : '50px',
                                    '& .MuiTypography-root': {
                                        color: palette.text.primary
                                    }
                                }}
                                title={title}
                                action={secondary}
                            />
                        )}
                    </Grid>
                    {title && element && (
                        <Grid
                            item
                            className='xs:mb-2 sm:mb-0 w-0 ml-[20px] sm:w-1/2 xs:ml-[0px]'
                            sx={{
                                paddingLeft: '18px',
                                paddingRight: '24px',
                                paddingTop: matchDownMd ? '10px' : '24px',
                                paddingBottom: matchDownMd ? '0px' : '18px',
                            }}
                        >
                            <Grid container justifyContent='end'>
                                {element}
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {/* content & header divider */}
                {title && !hideDivider && !matchDownMd &&
                    <Divider
                        style={{
                            backgroundColor: theme.palette.border.main,
                        }}
                    />
                }

                {/* card content */}
                {content && (
                    <CardContent
                        ref={cardContentRef}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                            padding: matchDownMd ? '16px' : '24px',
                            paddingTop: (matchDownMd || hideDivider) ? '16px' : '50px',
                            paddingLeft: matchDownMd ? '16px' : '50px',
                            paddingBottom: matchDownMd && '30px !important',
                            background: mode === 'dark' ? (matchDownMd ? palette.background.default : palette.background.paper) : (matchDownMd ? 'white' : palette.background.paper),
                            height: !matchDownMd ? cardHeight : 'auto',
                            overflowY: !matchDownMd && 'auto',
                            ...contentSX
                        }}
                        className={`${contentClass}`}
                    >
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    element: PropTypes.element,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    hideDivider: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
