/* eslint-disable */
import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// material-ui
import { Avatar, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { gridSpacing } from '../../store/constant';
import { useDispatch, useSelector } from '../../store';
// assets
import MainCard from '../../ui-elements/MainCard';

import { createAgent, getAllAgentTemplates } from '../../store/slices/agent';
import { LoadingButton } from '@mui/lab';
import AgentTemplateSkeletonLoader from '../../ui-elements/skeleton/AgentTemplateSkeletonLoader';
import './style.css'

const AgentTemplateSelect = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { agentTemplates, templatesLoading, createAgentLoading } = useSelector((state) => state.agent);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = React.useState(null)

    React.useEffect(() => {
        async function fetch() {
            await dispatch(getAllAgentTemplates())
        }
        fetch()
    }, [])

    const handleCreateAgent = async (agentValue, index) => {

        try {
            setSelectedTemplateIndex(index)
            const res = await dispatch(createAgent(agentValue));
            if (agentValue?.type === 'OTHERS') {
                navigate(`/dashboard/assistants/other/${res?.data?._id || ""}`)
            }
            else if (agentValue?.type === 'FINANCIAL') {
                navigate(`/dashboard/assistants/financial/${res?.data?._id || ""}`)
            }
        }
        catch (error) {

        }
        finally {
            setSelectedTemplateIndex(null)
        }
    }

    return (

        <MainCard
            content={true}
            title={false}
            contentSX={{
            }}
        >
            <Grid
                container
                spacing={gridSpacing}
                className='mt-2'
            >
                <Grid item xs={12} lg={12} className='flex flex-col justify-center items-center'>
                    <div className='flex flex-col w-full justify-center items-center gap-2 mb-8'>
                        <h1 className='text-2xl font-semibold text-center'>Choose Your Ready-Made AI Agent</h1>
                        <p className='text-center text-tertiary'>
                            Select from our wide range of template agents designed for specific<br className='hidden sm:block' />{' '}
                            business needs. Simply pick, customize, and start calling!
                        </p>
                    </div>
                    {!templatesLoading ?
                        <Grid
                            className='flex flex-col md:grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 justify-center items-center'
                        >
                            {agentTemplates &&
                                agentTemplates?.map((template, index) => {
                                    const details = template?.agent_info
                                    const agentValue = template?.agent
                                    const is_editable = template?.is_editable;
                                    const type = template?.type
                                    const template_id = template?._id

                                    const newAgent = {
                                        ...agentValue,
                                        is_editable: is_editable,
                                        type: type,
                                        template: template_id
                                    }

                                    return (
                                        <div
                                            key={index}
                                            className={`min-h-[250px] min-w-[300px] w-[300px] flex flex-col gap-4 rounded-lg px-4 py-4 shadow-card-shadow transition-all duration-300 hover:shadow-[0_0_10px_2px_rgba(22,156,217,0.5)]`}
                                            style={{
                                                border: '1px solid',
                                                borderColor: theme.palette.border.main,
                                                background: mode === 'dark' ? theme.palette.background.gradientDark : theme.palette.background.paper,
                                            }}
                                        >
                                            <div className='flex flex-col items-start justify-start gap-2'>
                                                <Avatar
                                                    size="sm"
                                                    sx={{
                                                        height: '40px',
                                                        width: '40px'
                                                    }}
                                                    src={details?.avatar_url}
                                                >
                                                    {details?.agent_name?.charAt(0).toUpperCase()}
                                                </Avatar>
                                                <h2 className='text-lg font-semibold'>{details?.title}</h2>
                                                <p className={`text-left text-tertiary`}>{details?.agent_name}</p>
                                            </div>

                                            <hr className='bg-gray-200' />

                                            <div name="features" className='flex flex-col gap-3 justify-start items-start px-4'>
                                                <ul className='text-sm text-tertiary' style={{ listStyleType: 'disc' }}>
                                                    {details?.description.map((data, index) => (
                                                        <li key={index}>{data}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <LoadingButton
                                                variant="contained"
                                                size="medium"
                                                loading={(createAgentLoading && selectedTemplateIndex === index)}
                                                onClick={() => handleCreateAgent(newAgent, index)}
                                            >
                                                Select
                                            </LoadingButton>
                                        </div>
                                    )
                                })}
                        </Grid>
                        :
                        <AgentTemplateSkeletonLoader />
                    }
                </Grid>
            </Grid>
        </MainCard>
    )
};

export default AgentTemplateSelect;
