/* eslint-disable */
import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// material-ui
import { Grid, CardContent, Button, } from '@mui/material';

import { gridSpacing } from '../../../store/constant';
import { useDispatch, useSelector } from '../../../store';

// assets
import MainCard from '../../../ui-elements/MainCard';
import { useTheme } from '@mui/material/styles';

import { Icon } from '@iconify/react/dist/iconify.js';
import { LoadingButton } from '@mui/lab';
import BreadcrumbsNav from '../../../ui-elements/BreadcrumbsTwo';


const NoPermissionPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const theme = useTheme()


    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <BreadcrumbsNav />
                <Grid item xs={12} className='flex justify-center items-center'>
                    <MainCard
                        content={false}
                    >
                        <Grid
                            container
                            spacing={gridSpacing}
                            className='h-[100vh] justify-center items-center'
                            sx={{
                                backgroundColor: theme.palette.background.default,
                            }}
                        >
                            <Grid item xs={12} lg={12}>
                                <CardContent
                                    sx={{
                                        border: '1px solid',
                                        borderColor: theme.palette.border.main,
                                        backgroundColor: theme.palette.background.default,
                                        height: '100%'
                                    }}
                                    className='rounded-xl px-10'
                                >
                                    <div className='my-10'>
                                        <div className='flex flex-col w-full justify-center items-center gap-1 text-2xl'>
                                            <Icon icon="material-symbols-light:lock-outline" className='w-20 h-20' />
                                            <h1 className='text-xl font-semibold'>Access Restricted</h1>
                                        </div>
                                        <div className='flex flex-col w-full justify-center items-center gap-2 mt-4'>
                                            <h1 className='mb-2 text-center text-tertiary'>
                                                It seems you do not have the necessary permissions to access this section.
                                                <br className='hidden sm:flex' />Please ask permission if you require further access rights.
                                            </h1>
                                            <div className='flex gap-2'>
                                                <Grid item className='flex gap-4 items-center flex-wrap  justify-center'>
                                                    <Button
                                                        type="button"
                                                        variant="outlined"
                                                        className='outlined-button-rounded'
                                                        size="large"
                                                        onClick={() => navigate('/dashboard/organization')}
                                                    >
                                                        View Members
                                                    </Button>
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default NoPermissionPage;
