/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

// ----------------------------------------------------------------------

const initialState = {
    clientAnalyticsData: null,

    loading: false,
    clientAnalyticsDataLoading: false,

};

const slice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isClientAnalyticsDataLoading(state, action) {
            state.clientAnalyticsDataLoading = action.payload;
        },

        setClientAnalyticsDataSuccess(state, action) {
            state.clientAnalyticsData = action.payload;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


// client_id=${client_id}&call_type=${call_type}&duration_type=${duration_type}&start_date=${start_date}&end_date=${end_date}
export function getClientAnalyticsData({ filter_query }) {
    return async () => {
        try {
            dispatch(slice.actions.isClientAnalyticsDataLoading(true));

            await new Promise(resolve => setTimeout(resolve, 1000));

            const response = await axios.get(`/client/analytics?${filter_query}`);
            dispatch(slice.actions.setClientAnalyticsDataSuccess(response.data?.data));

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isClientAnalyticsDataLoading(false));
        }
    }
}
