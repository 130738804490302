
// assets
import { IconDashboard, IconPhone, IconRobot, IconHistory, IconHeadset, IconUser, IconCreditCard, IconCode, IconPhoneOutgoing, IconAnalyze, IconBook2, IconReportAnalytics
} from '@tabler/icons';

export const icons = {
    IconDashboard,
    IconPhone,
    IconRobot,
    IconHistory,
    IconHeadset,
    IconUser,
    IconCreditCard,
    IconCode,
    IconPhoneOutgoing,
    IconAnalyze,
    IconBook2,
    IconReportAnalytics
};

// ==============================|| MENU ITEMS ||============================== //

const UnderMaintenance = ({ name }) => {
    return (
        <div className='flex gap-2'>
            <p>{name}</p>
            <sup className='text-[10px] font-semibold mt-[8px]'>Beta</sup>
        </div>
    )
}

export const menuItems = {
    id: 'dashboard',
    title: "",
    icon: 'IconDashboard',
    type: 'group',
    class: 'dashboard_tab',
    children: [
        {
            id: 'numbers',
            title: "Numbers",
            type: 'item',
            url: '/dashboard/numbers',
            icon: 'IconPhone',
            tempIcon: 'sharp-call',
            class: 'numbers_tab'
        },
        {
            id: 'assistant',
            title: "Assistants",
            type: 'collapse',
            icon: 'IconRobot',
            class: 'assistants_tab',
            children: [
                {
                    id: 'agents',
                    title: "General Agents",
                    type: 'item',
                    url: '/dashboard/assistants/other',
                    breadcrumbs: false,
                    class: 'agents_tab',
                },
                {
                    id: 'financial_agent',
                    title: "Financial Agents",
                    type: 'item',
                    url: '/dashboard/assistants/financial',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'ai manager',
            title: "Ai Manager",
            type: 'item',
            url: '/dashboard/ai-manager',
            icon: 'IconAnalyze',
            breadcrumbs: false,
            class: 'ai_manager_tab',
        },
        {
            id: 'call history',
            title: "Call History",
            type: 'item',
            url: '/dashboard/call-history',
            icon: 'IconHistory',
            breadcrumbs: false,
            class: 'call_history_tab',
        },
        {
            id: 'outbound_call',
            title: "Outbound Call",
            type: 'item',
            url: '/dashboard/outbound-call',
            icon: 'IconPhoneOutgoing',
            breadcrumbs: false,
            class: 'outbound_call_tab',
        },
        {
            id: 'developer',
            title: "Developer",
            type: 'item',
            url: '/dashboard/developer/api-keys',
            icon: 'IconCode',
            breadcrumbs: false,
            class: 'api_key_tab',
        },
        {
            id: 'support',
            title: "Support",
            type: 'item',
            url: '/dashboard/support',
            icon: 'IconHeadset',
            breadcrumbs: false,
            class: 'support_tab',
        },
    ]
};


