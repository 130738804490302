/* eslint-disable */
import { lazy } from 'react';

// project imports
import Loadable from '../ui-elements/Loadable';
import MainLayout from '../layout/MainLayout';

// GUARDS IMPORT
import AuthGuard from '../utils/guards/AuthGuard';
import { NumberBuyGuard, NumbersReadGuard, NumbersSettingGuard } from '../utils/guards/NumbersGuard';
import { ViewAgentsGuard } from '../utils/guards/AssistantGuards';
import { CallHistoryReadGuard } from '../utils/guards/CallHistoryGuard';
import { ApiKeysReadGuard } from '../utils/guards/ApiKeyGuard';
import NavMotion from '../layout/NavMotion';
import { ObcReadGuard, ObcWriteGuard } from '../utils/guards/OutboundGuard';
import { KnowledgeBasesReadGuard } from '../utils/guards/KnowledgeBaseGuard';

const OtherAgents = Loadable(lazy(() => import('../views/otherAgent')));
const FinancialAgents = Loadable(lazy(() => import('../views/financialAgent')));
const AgentAnalytics = Loadable(lazy(() => import('../views/aiManager')));
const ClientAnalytics = Loadable(lazy(() => import('../views/clientManager')));
const NumberList = Loadable(lazy(() => import('../views/numbers')));
const BuyNumber = Loadable(lazy(() => import('../views/numbers/buyNumber')));
const NumberHealthCheck = Loadable(lazy(() => import('../views/numbers/HealthCheck')));

const UserProfile = Loadable(lazy(() => import('../views/user-profile/indexTwo')));
const PhoneNumberSettings = Loadable(lazy(() => import('../views/numbers/phoneNumberSettings')));
const CallHistory = Loadable(lazy(() => import('../views/callHistory')));
const SupportFrom = Loadable(lazy(() => import('../views/support')));
const SupportReplies = Loadable(lazy(() => import('../views/support/supportReplies')));
const PostTransactionPage = Loadable(lazy(() => import('../views/pages/others/postTransactionPage')));
const SupportTable = Loadable(lazy(() => import('../views/support/supportTable')));
const Developer = Loadable(lazy(() => import('../views/Developer')));
const PersonalAssistant = Loadable(lazy(() => import('../views/personalAssistant')));
const ChatBots = Loadable(lazy(() => import('../views/chatBot')));

const OutboundCallTable = Loadable(lazy(() => import('../views/outboundCall/obcTable')));
const OutboundCallForm = Loadable(lazy(() => import('../views/outboundCall/createEditObc')));
const Organization = Loadable(lazy(() => import('../views/organization')));
const ObcLogsPage = Loadable(lazy(() => import('../views/outboundCall/obcLogs')));
const KnowledgeBase = Loadable(lazy(() => import('../views/KnowledgeBase/knowledgeBaseTable')));


//No Lazy Import
import QuickStart from '../views/dashboard/quickStart'
import AgentTemplateSelect from '../views/dashboard/agentTemplates'
import SingleOutboundCall from '../views/outboundCall/SingleOutboundCall';

const MainRoutes = {
    path: '/dashboard/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [

        {
            path: "numbers",
            children: [
                {
                    path: "",
                    element: (
                        <NumbersReadGuard>
                            <NavMotion>
                                <NumberList />
                            </NavMotion>
                        </NumbersReadGuard>
                    )
                },
                {
                    path: ":id",
                    element: (
                        <NumbersReadGuard>
                            <NavMotion>
                                <PhoneNumberSettings />
                            </NavMotion>
                        </NumbersReadGuard>

                    )
                },
                {
                    path: "buy",
                    element: (
                        <NumberBuyGuard>
                            <NavMotion>
                                <BuyNumber />
                            </NavMotion>
                        </NumberBuyGuard>
                    )
                },
                {
                    path: "check-health",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <NumberHealthCheck />
                            </NavMotion>
                        </ViewAgentsGuard>
                    )
                },
            ]
        },
        {
            path: "assistants",
            children: [
                {
                    path: "other",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <OtherAgents />
                            </NavMotion>
                        </ViewAgentsGuard>
                    )
                },
                {
                    path: "other/:id",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <OtherAgents />
                            </NavMotion>
                        </ViewAgentsGuard>

                    )
                },
                {
                    path: "financial",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <FinancialAgents />
                            </NavMotion>
                        </ViewAgentsGuard>
                    )
                },
                {
                    path: "financial/:id",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <FinancialAgents />
                            </NavMotion>
                        </ViewAgentsGuard>
                    )
                },
                {
                    path: "personal-assistant",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <PersonalAssistant />
                            </NavMotion>
                        </ViewAgentsGuard>
                    )
                },
                {
                    path: "personal-assistant/:id",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <PersonalAssistant />
                            </NavMotion>
                        </ViewAgentsGuard>
                    )
                },
            ]
        },
        {
            path: "knowledge-base",
            element: (
                <KnowledgeBasesReadGuard>
                    <NavMotion>
                        <KnowledgeBase />
                    </NavMotion>
                </KnowledgeBasesReadGuard>

            )
        },
        {
            path: "knowledge-base/:id",
            element: (
                <KnowledgeBasesReadGuard>
                    <NavMotion>
                        <KnowledgeBase />
                    </NavMotion>
                </KnowledgeBasesReadGuard>

            )
        },
        {
            path: "personal-assistant",
            element: (

                <NavMotion>
                    <PersonalAssistant />
                </NavMotion>

            )
        },
        {
            path: "personal-assistant/:id",
            element: (
                <NavMotion>
                    <PersonalAssistant />
                </NavMotion>

            )
        },
        {
            path: "chatBots",
            element: (
                <NavMotion>
                    <ChatBots />
                </NavMotion>

            )
        },
        {
            path: "chatBots/:id",
            element: (
                <NavMotion>
                    <ChatBots />
                </NavMotion>

            )
        },
        {
            path: "ai-manager",
            element: (
                <>
                    <AgentAnalytics />
                </>
            )
        },
        {
            path: "client-manager",
            element: (
                <>
                    <ClientAnalytics />
                </>
            )
        },
        {
            path: "call-history",
            element: (
                <CallHistoryReadGuard>
                    <NavMotion>
                        <CallHistory />
                    </NavMotion>
                </CallHistoryReadGuard>

            )
        },
        {
            path: "call-history/:id",
            element: (
                <CallHistoryReadGuard>
                    <NavMotion>
                        <CallHistory />
                    </NavMotion>
                </CallHistoryReadGuard>

            )
        },
        // {
        //     path: "subscription-plan",
        //     element: (
        //         <SubscriptionReadGuard>
        //             <NavMotion>
        //                 <SubscriptionPlan />
        //             </NavMotion>
        //         </SubscriptionReadGuard>

        //     )
        // },
        {
            path: "support",
            element: (
                <NavMotion>
                    <SupportTable />
                </NavMotion>

            )
        },
        {
            path: "support/create",
            element: (
                <NavMotion>
                    <SupportFrom />
                </NavMotion>

            )
        },
        {
            path: "support/replies",
            element: (
                <NavMotion>
                    <SupportReplies />
                </NavMotion>

            )
        },
        {
            path: "developer",
            element: (
                <ApiKeysReadGuard>
                    <NavMotion>
                        <Developer />
                    </NavMotion>
                </ApiKeysReadGuard>

            )
        },
        {
            path: "outbound-call",
            element: (
                <NavMotion>
                    <ObcReadGuard>
                        <OutboundCallTable />
                    </ObcReadGuard>
                </NavMotion>

            )
        },
        {
            path: "outbound-call/create",
            element: (
                <NavMotion>
                    <ObcWriteGuard>
                        <OutboundCallForm />
                    </ObcWriteGuard>
                </NavMotion>

            )
        },
        {
            path: "outbound-call/edit/:id",
            element: (
                <NavMotion>
                    <ObcWriteGuard>
                        <OutboundCallForm />
                    </ObcWriteGuard>
                </NavMotion>

            )
        },
        {
            path: "outbound-call/logs",
            element: (
                <NavMotion>
                    <ObcReadGuard>
                        <ObcLogsPage />
                    </ObcReadGuard>
                </NavMotion>

            )
        },
        {
            path: "demo-outbound-call",
            element: (
                <NavMotion>
                    <ObcReadGuard>
                        <SingleOutboundCall />
                    </ObcReadGuard>
                </NavMotion>

            )
        },
        {
            path: "user-profile",
            element: (
                <NavMotion>
                    <UserProfile />
                </NavMotion>

            )
        },
        {
            path: "organization",
            element: (
                <NavMotion>
                    <Organization />
                </NavMotion>

            )
        },
        {
            path: "post-transaction",
            element: (
                <NavMotion>
                    <PostTransactionPage />
                </NavMotion>

            )
        },
        {
            path: "quick-start",
            element: (
                <NavMotion>
                    <QuickStart />
                </NavMotion>
            )
        },
        {
            path: "quick-start/select-template",
            element: (
                <NavMotion>
                    <AgentTemplateSelect />
                </NavMotion>
            )
        },

    ]
};

export default MainRoutes