import React from 'react';
import { Drawer, Box, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from '../../../store';
import { closeDetailsDrawer } from '../../../store/slices/detailsDrawer';
import { ClickableCancelIcon } from '../../../ui-elements/IconifyIcon';

// ===============================|| UI DRAWER - RESPONSIVE ||=============================== //

export default function DetailsDrawer() {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const dispatch = useDispatch();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { open, title, element, style } = useSelector((state) => state.detailsDrawer);

    const handleClose = () => {
        dispatch(closeDetailsDrawer());
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: matchDownMd ? '100%' : '42rem',
                    backgroundColor: mode === 'dark' ? theme.palette.background.gradientDark : theme.palette.background.default,
                    borderRadius: '10px 0 0 10px', // Rounded edges for the drawer
                    display: 'flex',
                    flexDirection: 'column',
                    ...style
                },
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Set backdrop color and opacity
                },
            }}
        >
            {/* Header Section */}
            <Box sx={{ px: 3, py: 2, flexShrink: 0 }}>
                <div
                    className="flex items-center justify-between"
                    style={{
                        color: theme.palette.text.primary,
                    }}
                >
                    <h2 className="text-lg font-semibold">{title}</h2>
                    <ClickableCancelIcon
                        handleClick={handleClose}
                        iconStyle={{
                            height: '25px',
                            width: '25px',
                        }}
                    />
                </div>
            </Box>
            <hr className="mb-2" style={{ borderColor: theme.palette.divider }} />

            {/* Scrollable Content Section */}
            <Box sx={{ overflowY: 'auto', flexGrow: 1, px: 2, pb: 2 }} className="scroll">
                {element}
            </Box>
        </Drawer>
    );
}