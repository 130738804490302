import { Icon } from "@iconify/react/dist/iconify.js"
import { IconButton } from "@mui/material"
import { useTheme } from "@mui/material/styles"

export const ClickableSettingIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="uil:setting"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableVerticalThreeDotIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="entypo:dots-three-vertical"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableEditIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="lucide:edit"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableDeleteIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="tabler:trash"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.error.main : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableInfoIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="ph:info-bold"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableCrossIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="basil:cross-outline"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickablePaperViewIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}
        >
            <Icon
                icon="carbon:data-view-alt"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableDownloadIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="material-symbols:download"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableExternalLinkIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="mingcute:external-link-line"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableCloneIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="prime:clone"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableCancelIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="iconoir:cancel"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableRoundedCancelIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="ic:round-cancel"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}

export const ClickableViewDetailsIcon = ({ style = {}, iconStyle = {}, handleClick = () => { }, disabled = false, className = "" }) => {
    const theme = useTheme()
    const mode = theme.palette.mode;

    return (
        <IconButton
            size="large"
            aria-label="more options"
            onClick={handleClick}
            style={{
                padding: '7px 7px',
                ...style
            }}
            className={className}
            disabled={disabled}

        >
            <Icon
                icon="carbon:data-view"
                style={{
                    height: '20px',
                    width: '20px',
                    color: mode === 'dark' ? theme.palette.color.secondaryLevelTwo : theme.palette.text.primary,
                    ...iconStyle,
                }}
            />
        </IconButton>
    )
}